import React, { useLayoutEffect } from "react";
import * as SDCCore from "scandit-web-datacapture-core";
import { Feedback } from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { BarcodeCaptureFeedback } from "scandit-web-datacapture-barcode";
import { logSentryAndConsoleError } from "../../common/scripts/logger/log";

interface Props {
  onScan: (scanResult: string) => void;
}

const engineLocation =
  "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/";

const Scanner: React.FC<Props> = ({ onScan }) => {
  useLayoutEffect(() => {
    async function runScanner() {
      await SDCCore.configure({
        licenseKey: String(process?.env?.REACT_APP_SCANDIT_LICENSE_KEY),
        libraryLocation: engineLocation,
        moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
      });

      const context = await SDCCore.DataCaptureContext.create();

      const camera = SDCCore.Camera.default;
      await context.setFrameSource(camera);

      const settings = new SDCBarcode.BarcodeCaptureSettings();
      settings.enableSymbologies([SDCBarcode.Symbology.DataMatrix]);
      settings.codeDuplicateFilter = -1;
      settings.settingsForSymbology(SDCBarcode.Symbology.Code39);

      const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);
      const barcodeCaptureFeedback = new BarcodeCaptureFeedback();
      const vibration = null;
      const sound = null;
      barcodeCaptureFeedback.success = new Feedback(vibration, sound);
      await barcodeCapture.setFeedback(barcodeCaptureFeedback);
      await barcodeCapture.setEnabled(false);

      barcodeCapture.addListener({
        didScan: async (barcodeCapture, session) => {
          // await barcodeCapture.setEnabled(false);
          const barcode = session.newlyRecognizedBarcodes[0];
          if (barcode.data) {
            onScan(barcode.data);
          }
          await barcodeCapture.setEnabled(true);
        },
      });

      const view = await SDCCore.DataCaptureView.forContext(context);
      const connectingElement = document.getElementById("data-capture-view");
      if (connectingElement) {
        view.connectToElement(connectingElement);
        view.addControl(new SDCCore.CameraSwitchControl());
      }

      const barcodeCaptureOverlay =
        await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          barcodeCapture,
          view,
          SDCBarcode.BarcodeCaptureOverlayStyle.Frame,
        );

      const viewfinder = new SDCCore.RectangularViewfinder(
        SDCCore.RectangularViewfinderStyle.Square,
        SDCCore.RectangularViewfinderLineStyle.Light,
      );

      await barcodeCaptureOverlay.setViewfinder(viewfinder);

      await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
      await barcodeCapture.setEnabled(true);
    }

    runScanner().catch(error => {
      logSentryAndConsoleError(error, "error");
    });
  }, [onScan]);

  return <div key="data-capture-view" id="data-capture-view" />;
};

export default Scanner;
