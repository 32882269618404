import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { setPathnameAfterLogin, setToken, setTokenMarketAll } from "../slices/loginSlice";
import { getCustomer, resetAccount } from "../../account/actions/accountActions";
import { toast } from "react-toastify";
import { addLineItems, initCart, resetCart } from "../../cart/actions/cartActions";
import { getStorageToken } from "../../state/init/login";
import { getState } from "../../state/scripts/state";
import { countrySwitch } from "../../layout/actions/layoutActions";
import { StorageKeys } from "../../common/constants/StorageKeys";
import Token from "../vo/Token";
import { serviceGetToken, serviceRevokeToken } from "../../commercelayer/services/token";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { resetBuffer } from "../../cart/slices/cartSlice";
import { setCartOpen } from "../../layout/slices/layoutSlice";
import LayerIo from "../../common/services/LayerIo";
import { getCountry, getLang } from "../../router/scripts/params";
import i18next from "i18next";
import { OWNER_TYPE } from "../constants/ownerType";
import { resetCustomers } from "../../salesagent/actions/salesagentActions";
import { resetCheckout } from "../../checkout/actions/checkoutActions";
import { resetOrder } from "../../order/actions/orderActions";
import { countryByPltyp } from "../../commercelayer/config/countryByPltyp";
import { gtmPushLoginCustomer } from "../../common/scripts/tagManager/dataLayer";
import { Customer } from "../../commercelayer/vo/customers";
import { logSentryAndConsole, logSentryAndConsoleError } from "../../common/scripts/logger/log";
import {
  initConsignmentStockOrder,
  resetConsignmentStockOrder,
} from "../../consignment-stock/actions/consignmentStockActions";

export async function initLogin() {
  const storageToken = getStorageToken(StorageKeys.LOGIN_TOKEN);
  const storageTokenMarketAll = getStorageToken(StorageKeys.LOGIN_TOKEN_MARKET_ALL);
  if (storageToken === null && storageTokenMarketAll === null) {
    await logout();
    return;
  }

  await setTokenStateAndStorage(storageToken);
  await setTokenMarketAllStateAndStorage(storageTokenMarketAll);

  if (storageToken !== null) {
    // only for customers (not for sales agents)
    if (storageToken.owner_type === OWNER_TYPE.CUSTOMER) {
      await getCustomer();
      const accountCustomer = getState().account.customer;
      await redirectCountryByPltyp(accountCustomer);
      await initCart();
      await initConsignmentStockOrder();
    }
  }
}

export async function setTokenStateAndStorage(token: Token | null) {
  dispatch(setToken(token));
  await setTokenStorage(token, StorageKeys.LOGIN_TOKEN);
}

export async function setTokenMarketAllStateAndStorage(token: Token | null) {
  dispatch(setTokenMarketAll(token));
  await setTokenStorage(token, StorageKeys.LOGIN_TOKEN_MARKET_ALL);
}

export async function setTokenStorage(token: Token | null, storageKey: string) {
  if (token === null) {
    localStorage.removeItem(storageKey);
    return;
  }
  localStorage.setItem(storageKey, JSON.stringify(token));
}

export async function getScopeByUser(email: string) {
  return await LayerIo.requestJsonRpc<any, any>("commercelayer-login", "getScopeByUser", {
    email,
  });
}

export async function login(username: string, password: string) {
  try {
    const scopeResponse = await getScopeByUser(username);
    const scope = scopeResponse?.data?.number ?? 0;
    if (scope === 0) {
      toast.info(i18next.t("REGISTRATION CUSTOMER MAIL TEXT"), {
        position: "top-center",
      });
      logSentryAndConsole(`No scope by user ${username}`, "warning");
      return;
    }

    const response: Token = await serviceGetToken(username, password, `market:${scope}`);
    if (!response) {
      await logout();
      return;
    }
    const responseMarketAll: Token = await serviceGetToken(username, password, `market:all`);
    if (!responseMarketAll) {
      await logout();
      return;
    }

    // Login with was successful
    await setTokenStateAndStorage(response);
    await setTokenMarketAllStateAndStorage(responseMarketAll);

    await getCustomer();
    const accountCustomer = getState().account.customer;
    await redirectCountryByPltyp(accountCustomer);
    gtmPushLoginCustomer(accountCustomer);

    await initCart();
    const cartState = getState().cart;
    await addLineItems(cartState.bufferBeforeLogin, false);
    dispatch(resetBuffer());
    await initConsignmentStockOrder();
    const loginState = getState().login;
    if (loginState.pathnameAfterLogin === null) {
      boundReplaceLocObj({ pathname: getLangCountry() });
    } else {
      boundReplaceLocObj({ pathname: loginState.pathnameAfterLogin });
      dispatch(setPathnameAfterLogin(null));
      // redirect again. wrong country could have been in pathnameAfterLogin
      await redirectCountryByPltyp(accountCustomer);
    }
  } catch (e: any) {
    await logout();
    const message = i18next.t("LOGIN WAS NOT SUCCESSFUL");
    toast.error(message, {
      position: "top-center",
      autoClose: 10000,
    });
    logSentryAndConsole(`${message}; username: ${username}`, "warning");
    logSentryAndConsoleError(e, "fatal");
  }
}

export async function logout() {
  await resetAccount();
  await resetCart();
  await resetConsignmentStockOrder();
  dispatch(setCartOpen(false));
  await resetCustomers();
  await resetCheckout();
  await resetOrder();
  const loginState = getState().login;
  if (loginState.token && loginState.token.owner_type === OWNER_TYPE.CUSTOMER) {
    await serviceRevokeToken(loginState.token.refresh_token);
  }
  await setTokenStateAndStorage(null);
  await setTokenMarketAllStateAndStorage(null);
}

export async function redirectCountryByPltyp(accountCustomer: Customer | null) {
  if (accountCustomer) {
    const pltyp: string | null = accountCustomer.attributes.metadata["s_dbt_sal-pltyp"];
    if (pltyp === null) {
      throw new Error("Attribute PLTYP undefined.");
    }
    const country = countryByPltyp[pltyp];
    if (country) {
      // https://3.basecamp.com/3101128/buckets/19695186/todos/3584788969
      await countrySwitch(country);
    }
  }
}

export async function resetPassword(email: string) {
  try {
    const response = await LayerIo.requestJsonRpc<any, any>("customer", "passwordReset", {
      emailAddress: email,
      userLanguage: getLang(),
      userCountry: getCountry(),
    });

    if (response) {
      toast.success(
        i18next.t("AN E-MAIL WITH YOUR PASSWORD RESET LINK WILL BE SENT TO YOU SHORTLY"),
        {
          position: "top-center",
          autoClose: 10000,
        },
      );
    } else {
      toast.error(i18next.t("ERROR WHILE RESETTING THE PASSWORD"), {
        position: "top-center",
      });
    }
  } catch (e: any) {
    toast.error(i18next.t("ERROR WHILE RESETTING THE PASSWORD"), {
      position: "top-center",
    });
    // Log error to console
    console.error(e);
  }
}
