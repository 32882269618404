import { HTTP_METHOD } from "../constants/HttpMethod";
import { toast } from "react-toastify";
import i18next from "i18next";

class FetchError extends Error {
  private response: any;

  constructor(message, response) {
    super(message); // (1)
    this.name = "FetchError"; // (2)
    this.response = response;
  }
}

export async function fetchService(
  method: string,
  apiUrl: string,
  accessToken: string | null,
  data: any = {},
) {
  const init: any = {};

  if (method === HTTP_METHOD.DELETE) {
    init.method = HTTP_METHOD.DELETE;
    init.headers = {
      Accept: `application/vnd.api+json`,
      Authorization: `Bearer ${accessToken ?? "NO-ACCESS-TOKEN"}`,
      "Content-Type": "application/vnd.api+json",
    };
  }

  if (method === HTTP_METHOD.GET) {
    init.headers = {
      Accept: `application/vnd.api+json`,
      Authorization: `Bearer ${accessToken ?? "NO-ACCESS-TOKEN"}`,
    };
  }

  if (method === HTTP_METHOD.PATCH) {
    init.method = HTTP_METHOD.PATCH;
    init.headers = {
      Accept: `application/vnd.api+json`,
      Authorization: `Bearer ${accessToken ?? "NO-ACCESS-TOKEN"}`,
      "Content-Type": "application/vnd.api+json",
    };
    init.body = JSON.stringify(data);
  }

  if (method === HTTP_METHOD.POST) {
    init.method = HTTP_METHOD.POST;
    init.headers = {
      Accept: `application/vnd.api+json`,
      Authorization: `Bearer ${accessToken ?? "NO-ACCESS-TOKEN"}`,
      "Content-Type": "application/vnd.api+json",
    };
    init.body = JSON.stringify(data);
  }

  const response = await fetch(
    `https://${process.env.REACT_APP_COMMERCELAYER_SUBDOMAIN}.commercelayer.io/${apiUrl}`,
    init,
  );

  if (response.ok) {
    if (method === HTTP_METHOD.DELETE) {
      // No JSON for DELETE
      return;
    }
    return await response.json();
  } else {
    const responseJson = await response.json();
    const message = `An error has occured: ${response.status}`;
    throw new FetchError(message, responseJson);
  }
}

export async function fetchPageService(
  method: string,
  apiUrl: string,
  accessToken: string | null,
  pageNumber = 1,
  pageSize = 25,
) {
  apiUrl += apiUrl.includes("?") ? "&" : "?";
  apiUrl += `page[size]=${pageSize}&page[number]=${pageNumber}`;

  return await fetchService(method, apiUrl, accessToken);
}

const MAX_FETCH_PAGES = 3;

export async function fetchAllService(method: string, apiUrl: string, accessToken: string | null) {
  const response = await fetchPageService(method, apiUrl, accessToken);

  // Fetch additional pages
  const pageCount = response.meta.page_count;
  if (pageCount > 1) {
    const pageMax = pageCount > MAX_FETCH_PAGES ? MAX_FETCH_PAGES : pageCount;
    for (let page = 2; page <= pageMax; page++) {
      let responsePage = await fetchPageService(method, apiUrl, accessToken, page);
      response.data = response.data.concat(responsePage.data);
      if (response.included && responsePage.included) {
        response.included = response.included.concat(responsePage.included);
      }
    }
  }

  if (pageCount > MAX_FETCH_PAGES) {
    toast.info(i18next.t("MORE RESULTS AVAILABLE USE FILTER"), { autoClose: 2500 });
  }

  return response;
}
